import React, { useState } from 'react';
import '../css/CategoryButton.css';
import { Edit, X } from 'lucide-react';

const CategoryButton = ({ item, isActive, onClick, onDelete, onEdit, isSubCategory }) => {
  const [showActions, setShowActions] = useState(false);

  return (
    <div 
      className="categoryButtonWrapper"
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
    >
      <button
        className={`categoryButton ${isActive ? 'active' : ''} ${isSubCategory ? 'subCategoryButton' : ''}`}
        onClick={() => onClick(isSubCategory ? item.SottoCategorieID : item.CategorieID)}
      >
        {isSubCategory ? item.NomeSottoCategoria : item.NomeCategoria}
      </button>
      {showActions && (
        <div className="actionIcons">
          <Edit 
            className="editIcon" 
            size={18}
            onClick={(e) => {
              e.stopPropagation();
              onEdit(isSubCategory ? item.SottoCategorieID : item.CategorieID);
            }}
          />
          <X
            className="deleteIcon" 
            size={18}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(isSubCategory ? item.SottoCategorieID : item.CategorieID);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CategoryButton;