import React, { useState } from 'react';
import axios from 'axios';
import '../css/Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await axios.post('https://c4w3idtkg2.execute-api.eu-central-1.amazonaws.com/dev/gestionale/login', {
        email,
        password
      });

      const { idToken, accessToken, refreshToken, picture, restaurantName, ristorantiID } = response.data;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('restaurantName', restaurantName);
      localStorage.setItem('restaurantLogo', picture);
      localStorage.setItem('ristorantiID', ristorantiID);
      
      window.location.href = '/dashboard';
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        setError('Login fallito. Per favore controlla le tue credenziali.');
      } else {
        setError('Errore di rete. Per favore riprova.');
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src="/assets/Logo foodlight_1.svg" alt="Foodlight Logo" className="logoLogin" />
        <h1 className="titleLogin">Benvenuto!</h1>
        <p className="subtitleLogin">Accedi per gestire i tuoi piatti in AR con Foodlight</p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input"
          />
          <button type="submit" className="button" disabled={isLoading}>
            {isLoading ? <div className="loading"></div> : 'Accedi'}
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
        <a className="forgot-password">Hai dimenticato la password?</a>
      </div>
    </div>
  );
};

export default Login;