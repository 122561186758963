//AddDishModal.js
import React, { useState, useCallback, Suspense, useRef, useEffect } from 'react';
import axios from 'axios';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import '../css/AddDishModal.css';
import uploadIcon from '../assets/Upload.svg';
import { useDropzone } from 'react-dropzone';
import { ModelPreview, captureModelPreview } from './ModelPreview';

const API_ENDPOINT = 'https://c4w3idtkg2.execute-api.eu-central-1.amazonaws.com/dev/gestionale/add-piatto';
const STANDARD_SIZE = 1; // Dimensione standard per tutti i piatti

const AddDishModal = ({ onClose, categories, subCategories }) => {
  const [dishName, setDishName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [modelFile, setModelFile] = useState(null);
  const [fileName, setFileName] = useState('Nessun file selezionato');
  const [modelPreview, setModelPreview] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [ambientIntensity, setAmbientIntensity] = useState(0.5);
  const modelCanvasRef = useRef(null);

  // Imposta la categoria iniziale se ce ne sono disponibili
  useEffect(() => {
    if (categories.length > 0 && selectedCategory === '') {
      setSelectedCategory(categories[0].CategorieID);
    }
  }, [categories]);

  useEffect(() => {
    if (selectedCategory) {
      const availableSubCategories = subCategories.filter(sc => sc.CategorieID === selectedCategory);
      if (availableSubCategories.length > 0) {
        setSelectedSubCategory(availableSubCategories[0].SottoCategorieID);
      } else {
        setSelectedSubCategory('');
      }
    } else {
      setSelectedSubCategory('');
    }
  }, [selectedCategory, subCategories]);

  const standardizeModel = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const loader = new GLTFLoader();
        loader.parse(event.target.result, '', (gltf) => {
          const model = gltf.scene;
  
          // Calcola la bounding box
          const box = new THREE.Box3().setFromObject(model);
          const size = box.getSize(new THREE.Vector3());
          const center = box.getCenter(new THREE.Vector3());
  
          // Trova la dimensione più grande
          const maxDim = Math.max(size.x, size.y, size.z);
  
          // Calcola la scala necessaria per raggiungere la dimensione standard
          const scale = STANDARD_SIZE / maxDim;
  
          // Applica la scala
          model.scale.multiplyScalar(scale);
  
          // Centra il modello
          model.position.sub(center.multiplyScalar(scale));
  
          // Esporta il modello modificato
          const exporter = new GLTFExporter();
          exporter.parse(model, (result) => {
            const output = JSON.stringify(result, null, 2);
            const blob = new Blob([output], { type: 'application/octet-stream' });
            resolve(new File([blob], file.name, { type: 'model/gltf-binary' }));
          }, { binary: true });
        }, reject);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      try {
        setIsLoading(true);
        const standardizedFile = await standardizeModel(file);
        setModelFile(standardizedFile);
        setFileName(standardizedFile.name);
        const objectUrl = URL.createObjectURL(standardizedFile);
        setModelPreview(objectUrl);
      } catch (error) {
        console.error('Error standardizing model:', error);
        setError('Errore nella standardizzazione del modello. Riprova.');
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'model/gltf-binary': ['.glb'],
      'model/gltf+json': ['.gltf']
    },
    multiple: false
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      let previewImageBase64 = null;
      if (modelCanvasRef.current) {
        previewImageBase64 = await captureModelPreview(modelCanvasRef);
      }

      const dishData = {
        NomePiatto: dishName,
        RistorantiID: localStorage.getItem('ristorantiID'),
        CategorieID: selectedCategory,
        SottoCategorieID: selectedSubCategory || null,
        Descrizione: description,
        Prezzo: parseFloat(price),
        ImmaginePiatto: previewImageBase64,
      };

      const response = await axios.post(API_ENDPOINT, {
        accessToken: localStorage.getItem('accessToken'),
        dishData: dishData
      });

      const { uploadURL, piattiID } = response.data;

      await axios.put(uploadURL, modelFile, {
        headers: { 'Content-Type': 'model/gltf-binary' }
      });

      console.log('Piatto aggiunto con successo. PiattiID:', piattiID);
      onClose();
    } catch (error) {
      console.error('Errore nell\'aggiunta del piatto:', error);
      setError('Si è verificato un errore durante l\'aggiunta del piatto. Riprova.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modalOverlay">
      <div className="modalContent">
        <h2>Aggiungi piatto</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="inputGroup">
            <input
              type="text"
              placeholder="Nome del piatto"
              value={dishName}
              onChange={(e) => setDishName(e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="00.00 €"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
              step="0.01"
              min="0"
            />
          </div>
          <div className="categorySelection">
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              required
            >
              <option value="">Seleziona una categoria</option>
              {categories.map((category) => (
                <option key={category.CategorieID} value={category.CategorieID}>
                  {category.NomeCategoria}
                </option>
              ))}
            </select>
            <select
              value={selectedSubCategory}
              onChange={(e) => setSelectedSubCategory(e.target.value)}
              disabled={!selectedCategory || subCategories.filter(sc => sc.CategorieID === selectedCategory).length === 0}
            >
              <option value="">
                {!selectedCategory ? "Seleziona prima una categoria" :
                 subCategories.filter(sc => sc.CategorieID === selectedCategory).length === 0 ?
                 "Nessuna sottocategoria disponibile" : "Seleziona una sottocategoria"}
              </option>
              {subCategories
                .filter(subCategory => subCategory.CategorieID === selectedCategory)
                .map((subCategory) => (
                  <option key={subCategory.SottoCategorieID} value={subCategory.SottoCategorieID}>
                    {subCategory.NomeSottoCategoria}
                  </option>
                ))}
            </select>
          </div>
          <div className="modelSection">
            {!modelPreview ? (
              <div {...getRootProps()} className={`fileUpload ${isDragActive ? 'active' : ''}`}>
                <input {...getInputProps()} required />
                <img src={uploadIcon} alt="Upload" className="uploadIcon" />
                <p>{fileName}</p>
                {isDragActive ? (
                  <p>Rilascia il file qui ...</p>
                ) : (
                  <p>Trascina un file .glb o .gltf qui, o clicca per selezionarlo</p>
                )}
              </div>
            ) : (
              <div className="modelPreviewContainer">
                <Suspense fallback={<div>Caricamento modello...</div>}>
                  <ModelPreview 
                    url={modelPreview} 
                    ref={modelCanvasRef}
                    ambientIntensity={ambientIntensity}
                  />
                </Suspense>
                <div className="lightingControls">
                  <label>
                    Luminosità:
                    <input 
                      type="range" 
                      min="1" 
                      max="3" 
                      step="0.1" 
                      value={ambientIntensity} 
                      onChange={(e) => setAmbientIntensity(parseFloat(e.target.value))}
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
          <textarea
            placeholder="Inserisci una descrizione del piatto"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <div className="modalActions">
            <button type="button" onClick={onClose} className="cancelButton" disabled={isLoading}>Annulla</button>
            <button type="submit" className="submitButton" disabled={isLoading}>
              {isLoading ? 'Caricamento...' : 'Aggiungi'}
            </button>
          </div>
        </form>
      </div>
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner"></div>
          <p>Caricamento in corso...</p>
        </div>
      )}
    </div>
  );
};

export default AddDishModal;