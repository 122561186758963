import React, { useState } from 'react';
import axios from 'axios';
import '../css/EditCategoryModal.css';

const API_ENDPOINT = 'https://c4w3idtkg2.execute-api.eu-central-1.amazonaws.com/dev/gestionale/edit-category';

const EditCategoryModal = ({ onClose, category, isSubCategory }) => {
    const [name, setName] = useState(isSubCategory ? category.NomeSottoCategoria : category.NomeCategoria);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setError('');
  
      const accessToken = localStorage.getItem('accessToken');
      const ristorantiID = localStorage.getItem('ristorantiID');
  
      if (!accessToken || !ristorantiID) {
        setError('Errore di autenticazione. Prova a effettuare nuovamente il login.');
        setIsLoading(false);
        return;
      }
  
      const payload = {
        accessToken,
        ristorantiID,
        categoryId: isSubCategory ? category.SottoCategorieID : category.CategorieID,
        newName: name,
        isSubCategory,
        categorieID: isSubCategory ? category.CategorieID : undefined
      };
  
      try {
        const response = await axios.post(API_ENDPOINT, payload);
        onClose(true); // Close modal and trigger refresh
      } catch (error) {
        if (error.response) {
          console.error('Error response:', error.response.data);
          setError(`Errore: ${error.response.data.message || 'Si è verificato un errore sconosciuto'}`);
        } else if (error.request) {
          setError('Nessuna risposta ricevuta dal server. Verifica la tua connessione.');
        } else {
          setError('Si è verificato un errore durante la richiesta.');
        }
        console.log('Error details:', {
          status: error.response?.status,
          data: error.response?.data,
          headers: error.response?.headers,
        });
      } finally {
        setIsLoading(false);
      }
    };

  return (
    <div className="edit-category-modal-overlay">
      <div className="edit-category-modal-content">
        <h2>Modifica nome {isSubCategory ? 'Sottocategoria' : 'Categoria'}</h2>
        {error && <div className="edit-category-modal-error">{error}</div>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Inserisci un nuovo nome"
            required
            className="edit-category-modal-input"
          />
          <div className="edit-category-modal-actions">
            <button type="button" onClick={() => onClose(false)} className="edit-category-modal-cancel">Annulla</button>
            <button type="submit" className="edit-category-modal-apply" disabled={isLoading}>
              {isLoading ? 'Caricamento...' : 'Applica'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCategoryModal;