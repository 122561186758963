import React, { useState, useCallback, Suspense, useRef, useEffect } from 'react';
import axios from 'axios';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import uploadIcon from '../assets/Upload.svg';
import { useDropzone } from 'react-dropzone';
import { ModelPreview, captureModelPreview } from './ModelPreview';
import "../css/EditDishModal.css"

const API_ENDPOINT = 'https://c4w3idtkg2.execute-api.eu-central-1.amazonaws.com/dev/gestionale/update-piatto';
const STANDARD_SIZE = 5; // Dimensione standard per tutti i piatti

class SimpleErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Errore catturato:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h3>Si è verificato un errore nel caricamento del modello 3D.</h3>
          <button onClick={() => this.setState({ hasError: false })}>Riprova</button>
        </div>
      );
    }

    return this.props.children;
  }
}

const EditDishModal = ({ onClose, categories, subCategories, dish }) => {
  const [dishName, setDishName] = useState(dish.NomePiatto);
  const [price, setPrice] = useState(dish.Prezzo.toString());
  const [description, setDescription] = useState(dish.Descrizione);
  const [modelFile, setModelFile] = useState(null);
  const [fileName, setFileName] = useState('Nessun nuovo file selezionato');
  const [modelPreview, setModelPreview] = useState(dish.SignedUrlModello);
  const [selectedCategory, setSelectedCategory] = useState(dish.CategorieID);
  const [selectedSubCategory, setSelectedSubCategory] = useState(dish.SottoCategorieID || '');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [ambientIntensity, setAmbientIntensity] = useState(0.5);
  const modelCanvasRef = useRef(null);

  useEffect(() => {
    if (selectedCategory) {
      const availableSubCategories = subCategories.filter(sc => sc.CategorieID === selectedCategory);
      if (availableSubCategories.length > 0 && !selectedSubCategory) {
        setSelectedSubCategory(availableSubCategories[0].SottoCategorieID);
      } else if (availableSubCategories.length === 0) {
        setSelectedSubCategory('');
      }
    } else {
      setSelectedSubCategory('');
    }
  }, [selectedCategory, subCategories, selectedSubCategory]);

  const standardizeModel = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const loader = new GLTFLoader();
        loader.parse(event.target.result, '', (gltf) => {
          const model = gltf.scene;
  
          const box = new THREE.Box3().setFromObject(model);
          const size = box.getSize(new THREE.Vector3());
          const center = box.getCenter(new THREE.Vector3());
  
          const maxDim = Math.max(size.x, size.y, size.z);
          const scale = STANDARD_SIZE / maxDim;
  
          model.scale.multiplyScalar(scale);
          model.position.sub(center.multiplyScalar(scale));
  
          const exporter = new GLTFExporter();
          exporter.parse(model, (result) => {
            const output = JSON.stringify(result, null, 2);
            const blob = new Blob([output], { type: 'application/octet-stream' });
            resolve(new File([blob], file.name, { type: 'model/gltf-binary' }));
          }, { binary: true });
        }, reject);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      try {
        setIsLoading(true);
        const standardizedFile = await standardizeModel(file);
        setModelFile(standardizedFile);
        setFileName(standardizedFile.name);
        const objectUrl = URL.createObjectURL(standardizedFile);
        setModelPreview(objectUrl);
      } catch (error) {
        console.error('Error standardizing model:', error);
        setError('Errore nella standardizzazione del modello. Riprova.');
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'model/gltf-binary': ['.glb'],
      'model/gltf+json': ['.gltf']
    },
    multiple: false
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
  
    try {
      let previewImageBase64 = null;
      if (modelCanvasRef.current) {
        previewImageBase64 = await captureModelPreview(modelCanvasRef);
      }
  
      const updatedDishData = {
        PiattiID: dish.PiattiID,
        NomePiatto: dishName,
        RistorantiID: localStorage.getItem('ristorantiID'),
        CategorieID: selectedCategory,
        SottoCategorieID: selectedSubCategory || null,
        Descrizione: description,
        Prezzo: parseFloat(price),
        ImmaginePiatto: previewImageBase64 || dish.ImmaginePiatto,
        newModelRequired: !!modelFile
      };
  
      console.log('Sending data to API:', updatedDishData);
  
      const response = await axios.post(API_ENDPOINT, {
        accessToken: localStorage.getItem('accessToken'),
        dishData: updatedDishData
      });
  
      console.log('API Response:', response.data);
  
      if (modelFile) {
        const { uploadURL } = response.data;
        if (!uploadURL) {
          throw new Error('Upload URL not provided by the server');
        }
        await axios.put(uploadURL, modelFile, {
          headers: { 
            'Content-Type': 'model/gltf-binary'
          }
        });
      }
  
      console.log('Piatto aggiornato con successo.');
      onClose(true);
    } catch (error) {
      console.error('Errore nell\'aggiornamento del piatto:', error);
      let errorMessage = 'Si è verificato un errore durante l\'aggiornamento del piatto. ';
      if (error.response) {
        console.error('Response data:', error.response.data);
        errorMessage += error.response.data.message || JSON.stringify(error.response.data);
      } else if (error.request) {
        errorMessage += 'Nessuna risposta ricevuta dal server.';
      } else {
        errorMessage += error.message;
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modalOverlay">
      <div className="modalContent">
        <h2>Modifica piatto</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="inputGroup">
            <label><strong>Nome piatto</strong></label>
            <input
              type="text"
              value={dishName}
              onChange={(e) => setDishName(e.target.value)}
              required
            />
          </div>
          <div className="inputGroup">
            <label><strong>Prezzo (€)</strong></label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
              step="0.01"
              min="0"
            />
          </div>
          <div className="categorySelection">
            <div className="inputGroup">
              <label><strong>Categoria</strong></label>
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                required
              >
                <option value="">Seleziona una categoria</option>
                {categories.map((category) => (
                  <option key={category.CategorieID} value={category.CategorieID}>
                    {category.NomeCategoria}
                  </option>
                ))}
              </select>
            </div>
            <div className="inputGroup">
              <label><strong>Sottocategoria</strong></label>
              <select
                value={selectedSubCategory}
                onChange={(e) => setSelectedSubCategory(e.target.value)}
                disabled={!selectedCategory || subCategories.filter(sc => sc.CategorieID === selectedCategory).length === 0}
              >
                <option value="">
                  {!selectedCategory ? "Seleziona prima una categoria" :
                   subCategories.filter(sc => sc.CategorieID === selectedCategory).length === 0 ?
                   "Nessuna sottocategoria disponibile" : "Seleziona una sottocategoria"}
                </option>
                {subCategories
                  .filter(subCategory => subCategory.CategorieID === selectedCategory)
                  .map((subCategory) => (
                    <option key={subCategory.SottoCategorieID} value={subCategory.SottoCategorieID}>
                      {subCategory.NomeSottoCategoria}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="modelSection-edit">
  <div className="modelPreviewContainer-edit">
    <label><strong>Modello 3D</strong></label>
    <SimpleErrorBoundary>
      <Suspense fallback={<div>Caricamento modello...</div>}>
        <ModelPreview 
          url={modelPreview}
          ref={modelCanvasRef}
          ambientIntensity={ambientIntensity}
        />
      </Suspense>
    </SimpleErrorBoundary>
    <div className="lightingControls-edit">
      <label>
        Luminosità:
        <input 
          type="range" 
          min="0.1" 
          max="3" 
          step="0.1" 
          value={ambientIntensity} 
          onChange={(e) => setAmbientIntensity(parseFloat(e.target.value))}
        />
      </label>
    </div>
  </div>
  <div className="modelUploadSection-edit">
    <div {...getRootProps()} className={`fileUpload-edit ${isDragActive ? 'active' : ''}`}>
      <input {...getInputProps()} />
      <img src={uploadIcon} alt="Upload" className="uploadIcon-edit" />
      <p>{fileName}</p>
      {isDragActive ? (
        <p>Rilascia il file qui ...</p>
      ) : (
        <p>Trascina un nuovo file .glb o .gltf qui, o clicca per selezionarlo</p>
      )}
    </div>
  </div>
</div>
          <div className="inputGroup">
            <label><strong>Descrizione</strong></label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className="modalActions">
            <button type="button" onClick={() => onClose(false)} className="cancelButton" disabled={isLoading}>Annulla</button>
            <button type="submit" className="submitButton" disabled={isLoading}>
              {isLoading ? 'Caricamento...' : 'Salva modifiche'}
            </button>
          </div>
        </form>
      </div>
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner"></div>
          <p>Caricamento in corso...</p>
        </div>
      )}
    </div>
  );
};

export default EditDishModal;