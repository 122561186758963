import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/Dashboard.css';
import CategoryButton from '../components/CategoryButton';
import DishCard from '../components/DishCard';
import Header from '../components/Header';
import AddCategoryModal from '../components/AddCategoryModal';
import AddDishModal from '../components/AddDishModal';
import EditDishModal from '../components/EditDishModal';
import EditCategoryModal from '../components/EditCategoryModal';



const API_ENDPOINT = 'https://c4w3idtkg2.execute-api.eu-central-1.amazonaws.com/dev/gestionale';

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
  </div>
);

const ConfirmationModal = ({ message, onConfirm, onCancel }) => (
  <div className="modal-overlay">
    <div className="confirmation-modal">
      <p>{message}</p>
      <div className="modal-actions">
        <button onClick={onCancel} className="cancel-button">Annulla</button>
        <button onClick={onConfirm} className="delete-button">Elimina</button>
      </div>
    </div>
  </div>
);

const Dashboard = () => {
  const [restaurantName, setRestaurantName] = useState('');
  const [restaurantLogo, setRestaurantLogo] = useState('');
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [dishes, setDishes] = useState([]);
  const [showAddDishModal, setShowAddDishModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dishToDelete, setDishToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [isAddingSubCategory, setIsAddingSubCategory] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showEditDishModal, setShowEditDishModal] = useState(false);
  const [dishToEdit, setDishToEdit] = useState(null);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);



  const navigate = useNavigate();

  useEffect(() => {
    const storedRestaurantName = localStorage.getItem('restaurantName');
    const storedRestaurantLogo = localStorage.getItem('restaurantLogo');
    const accessToken = localStorage.getItem('accessToken');
    const ristorantiID = localStorage.getItem('ristorantiID');

    if (!accessToken) {
      navigate('/login');
      return;
    }

    if (storedRestaurantName && storedRestaurantLogo) {
      setRestaurantName(storedRestaurantName);
      setRestaurantLogo(`data:image/png;base64,${storedRestaurantLogo}`);
    }

    setIsLoading(true);
    fetchCategories();
    fetchDishes();
  }, [navigate]);

  const fetchCategories = async () => {
    const accessToken = localStorage.getItem('accessToken');
    const ristorantiID = localStorage.getItem('ristorantiID');
    
    try {
      const response = await axios.post(`${API_ENDPOINT}/get-categories`, {
        accessToken,
        RistorantiID: ristorantiID
      });

      setCategories(response.data.categories);
      setSubCategories(response.data.subCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchDishes = async () => {
    const accessToken = localStorage.getItem('accessToken');
    const ristorantiID = localStorage.getItem('ristorantiID');

    try {
      const response = await axios.post(`${API_ENDPOINT}/get-piatti`, {
        accessToken,
        RistorantiID: ristorantiID
      });

      console.log('Fetched dishes:', response.data);
      setDishes(response.data);
    } catch (error) {
      console.error('Error fetching dishes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditDish = (dish) => {
    setDishToEdit(dish);
    setShowEditDishModal(true);
  };

  const handleCloseEditDishModal = (wasUpdated) => {
    setShowEditDishModal(false);
    setDishToEdit(null);
    if (wasUpdated) {
      fetchDishes(); // Ricarica i piatti se sono state apportate modifiche
    }
  };


  const handleEditCategory = (categoryId) => {
    const categoryToEdit = categories.find(cat => cat.CategorieID === categoryId) ||
                           subCategories.find(subCat => subCat.SottoCategorieID === categoryId);
    setCategoryToEdit(categoryToEdit);
    setShowEditCategoryModal(true);
  };

  const handleCloseEditCategoryModal = (wasUpdated) => {
    setShowEditCategoryModal(false);
    setCategoryToEdit(null);
    if (wasUpdated) {
      fetchCategories(); // Reload categories if changes were made
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('restaurantName');
    localStorage.removeItem('restaurantLogo');
    localStorage.removeItem('ristorantiID');
    navigate('/login');
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId === selectedCategory ? null : categoryId);
    setSelectedSubCategory(null);
  };

  const handleSubCategoryClick = (subCategoryId) => {
    setSelectedSubCategory(subCategoryId === selectedSubCategory ? null : subCategoryId);
  };

  const handleDeleteCategory = (categoryId) => {
    setItemToDelete({ type: 'category', id: categoryId });
  };

  const handleDeleteSubCategory = (subCategoryId) => {
    setItemToDelete({ type: 'subcategory', id: subCategoryId });
  };

  const confirmDelete = async () => {
    if (!itemToDelete) return;

    const accessToken = localStorage.getItem('accessToken');
    const ristorantiID = localStorage.getItem('ristorantiID');

    try {
      await axios.post(`${API_ENDPOINT}/delete-category`, {
        accessToken,
        ristorantiID,
        ...(itemToDelete.type === 'category' 
          ? { categorieID: itemToDelete.id }
          : { sottoCategorieID: itemToDelete.id })
      });

      if (itemToDelete.type === 'category') {
        setCategories(categories.filter(cat => cat.CategorieID !== itemToDelete.id));
        if (selectedCategory === itemToDelete.id) {
          setSelectedCategory(null);
          setSelectedSubCategory(null);
        }
      } else {
        setSubCategories(subCategories.filter(subCat => subCat.SottoCategorieID !== itemToDelete.id));
        if (selectedSubCategory === itemToDelete.id) {
          setSelectedSubCategory(null);
        }
      }
    } catch (error) {
      console.error(`Error deleting ${itemToDelete.type}:`, error);
      // Gestisci l'errore (es. mostra un messaggio all'utente)
    } finally {
      setItemToDelete(null);
    }
  };

  const cancelDelete = () => {
    setItemToDelete(null);
  };

  const handleAddDishClick = () => {
    setShowAddDishModal(true);
  };

  const handleCloseAddDishModal = () => {
    setShowAddDishModal(false);
    fetchDishes();
  };

  const handleDeleteDish = (dish) => {
    setDishToDelete(dish);
  };

  const confirmDeleteDish = async () => {
    if (!dishToDelete) return;

    setIsDeleting(true);
    const accessToken = localStorage.getItem('accessToken');
    const ristorantiID = localStorage.getItem('ristorantiID');

    try {
      await axios.post(`${API_ENDPOINT}/delete-piatto`, {
        accessToken,
        piattiID: dishToDelete.PiattiID,
        ristorantiID
      });

      setDishes(dishes.filter(dish => dish.PiattiID !== dishToDelete.PiattiID));
    } catch (error) {
      console.error('Error deleting dish:', error);
    } finally {
      setDishToDelete(null);
      setIsDeleting(false);
    }
  };

  const cancelDeleteDish = () => {
    setDishToDelete(null);
  };

  const handleAddCategory = () => {
    setIsAddingSubCategory(false);
    setShowAddCategoryModal(true);
  };

  const handleAddSubCategory = () => {
    if (!selectedCategory) {
      alert('Seleziona prima una categoria');
      return;
    }
    setIsAddingSubCategory(true);
    setShowAddCategoryModal(true);
  };

  const handleCategoryAdded = async (newCategoryData) => {
    if (isAddingSubCategory) {
      setSubCategories([...subCategories, newCategoryData]);
    } else {
      setCategories([...categories, newCategoryData]);
    }
    setShowAddCategoryModal(false);
    await fetchCategories();
  };

  const handleToggleVisibility = async (dish) => {
    const accessToken = localStorage.getItem('accessToken');
    const ristorantiID = localStorage.getItem('ristorantiID');
  
    try {
      const response = await axios.post(`${API_ENDPOINT}/toggle-dish-visibility`, {
        accessToken,
        ristorantiID,
        piattiID: dish.PiattiID
      });
  
      if (response.data.success) {
        setDishes(dishes.map(d => 
          d.PiattiID === dish.PiattiID 
            ? { ...d, Visible: response.data.updatedValues.Visible } 
            : d
        ));
      } else {
        console.error('Failed to toggle visibility:', response.data.message);
        // Mostra un messaggio di errore all'utente
      }
    } catch (error) {
      console.error('Error toggling dish visibility:', error);
      // Mostra un messaggio di errore all'utente
    }
  };

  const filteredDishes = dishes.filter(dish => {
    if (selectedSubCategory) {
      return dish.CategorieID === selectedCategory && dish.SottoCategorieID === selectedSubCategory;
    }
    if (selectedCategory) {
      return dish.CategorieID === selectedCategory;
    }
    return true;
  });

  return (
    <div className="container">
      <Header
        restaurantLogo={restaurantLogo}
        restaurantName={restaurantName}
        onLogout={handleLogout}
      />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="content">
          <h2>Il mio menù</h2>
          <div className="categoryContainer">
            {categories.map((category) => (
            <CategoryButton
                key={category.CategorieID}
                item={category}
                isActive={selectedCategory === category.CategorieID}
                onClick={handleCategoryClick}
                onDelete={handleDeleteCategory}
                onEdit={handleEditCategory}
                isSubCategory={false}
            />
            ))}
            <button className="addButton" onClick={handleAddCategory}>
              Aggiungi categoria
            </button>
          </div>
          <div className="subCategoryContainer">
            {selectedCategory && subCategories
              .filter((subCategory) => subCategory.CategorieID === selectedCategory)
              .map((subCategory) => (
                <CategoryButton
                key={subCategory.SottoCategorieID}
                item={subCategory}
                isActive={selectedSubCategory === subCategory.SottoCategorieID}
                onClick={handleSubCategoryClick}
                onDelete={handleDeleteSubCategory}
                onEdit={handleEditCategory}
                isSubCategory={true}
              />
              ))}
            <button className="addButton" onClick={handleAddSubCategory}>
              Aggiungi sottocategoria
            </button>
          </div>
          <div className="dishesContainer">
            {filteredDishes.map(dish => (
              <DishCard 
                key={dish.PiattiID} 
                dish={dish} 
                onDelete={handleDeleteDish}
                onToggleVisibility={handleToggleVisibility}
                onEdit={handleEditDish}
              />
            ))}
          </div>
          <button className="addDishButton" onClick={handleAddDishClick}>
            Aggiungi piatto
          </button>
        </div>
      )}
            {showEditCategoryModal && (
        <EditCategoryModal
          onClose={handleCloseEditCategoryModal}
          category={categoryToEdit}
          isSubCategory={categoryToEdit && 'SottoCategorieID' in categoryToEdit}
        />
            )}
      {showEditDishModal && (
        <EditDishModal 
          onClose={handleCloseEditDishModal} 
          categories={categories}
          subCategories={subCategories}
          dish={dishToEdit}
        />
      )}
      {showAddDishModal && (
        <AddDishModal 
          onClose={handleCloseAddDishModal} 
          categories={categories}
          subCategories={subCategories}
        />
      )}
      {dishToDelete && (
        <ConfirmationModal
          message="Sei sicuro di volere eliminare questo piatto?"
          onConfirm={confirmDeleteDish}
          onCancel={cancelDeleteDish}
        />
      )}
      {isDeleting && (
        <div className="loading-overlay">
          <LoadingSpinner />
        </div>
      )}
      {showAddCategoryModal && (
        <AddCategoryModal
          onClose={() => setShowAddCategoryModal(false)}
          isSubCategory={isAddingSubCategory}
          parentCategoryId={isAddingSubCategory ? selectedCategory : undefined}
          onAdd={handleCategoryAdded}
        />
      )}
      {itemToDelete && (
        <ConfirmationModal
          message={`Sei sicuro di voler eliminare questa ${itemToDelete.type === 'category' ? 'categoria' : 'sottocategoria'}?`}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
    </div>
  );
};

export default Dashboard;