import React from 'react';
import '../css/Header.css';

const Header = ({ restaurantLogo, restaurantName, onLogout }) => (
  <div className="header">
    <div className="restaurantInfo">
      <img src={restaurantLogo} alt="Restaurant Logo" className="logo" />
      <h1 className="title">{restaurantName}</h1>
    </div>
    <button className="logoutButton" onClick={onLogout}>Logout</button>
  </div>
);

export default Header;
