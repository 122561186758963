import React from 'react';
import '../css/DishCard.css';
import { FaEdit, FaEye, FaEyeSlash, FaTrash } from 'react-icons/fa';

const DishCard = ({ dish, onEdit, onToggleVisibility, onDelete }) => {
  const handleEdit = () => {
    onEdit(dish);
  };

  const handleToggleVisibility = () => {
    onToggleVisibility(dish);
  };

  const handleDelete = () => {
    onDelete(dish);
  };

  return (
    <div className="dishCard">
      <div className="dishImageContainer">
        <img src={`data:image/jpeg;base64,${dish.ImmaginePiatto}`} alt={dish.NomePiatto} className="dishImage" />
      </div>
      <h3>{dish.NomePiatto}</h3>
      <div className="dishActions">
        <button onClick={handleEdit} className="actionButton editButton">
          <FaEdit />
        </button>
        <button onClick={handleToggleVisibility} className="actionButton visibilityButton">
          {dish.Visible === 1 ? <FaEye /> : <FaEyeSlash />}
        </button>
        <button onClick={handleDelete} className="actionButton deleteButton">
          <FaTrash />
        </button>
      </div>
    </div>
  );
};

export default DishCard;