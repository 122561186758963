import React, { useState } from 'react';
import axios from 'axios';
import '../css/AddCategoryModal.css';

const API_ENDPOINT = 'https://c4w3idtkg2.execute-api.eu-central-1.amazonaws.com/dev/gestionale/add-category';

const AddCategoryModal = ({ onClose, isSubCategory, parentCategoryId, onAdd }) => {
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    const accessToken = localStorage.getItem('accessToken');
    const ristorantiID = localStorage.getItem('ristorantiID');

    if (!accessToken || !ristorantiID) {
      setError('Errore di autenticazione. Prova a effettuare nuovamente il login.');
      setIsLoading(false);
      return;
    }

    const payload = {
      accessToken,
      ristorantiID,
      nome: name,
      isSubCategory,
      ...(isSubCategory && { parentCategoryId })
    };

    console.log('Sending payload:', payload);

    try {
      const response = await axios.post(API_ENDPOINT, payload);
      console.log('Response:', response.data);
      onAdd(response.data);
      onClose();
    } catch (error) {
      console.error('Error adding category:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        setError(`Errore: ${error.response.data.message || 'Si è verificato un errore sconosciuto'}`);
      } else if (error.request) {
        setError('Nessuna risposta ricevuta dal server. Verifica la tua connessione.');
      } else {
        setError('Si è verificato un errore durante la richiesta.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="add-category-modal-overlay">
      <div className="add-category-modal-content">
        <h2>{isSubCategory ? 'Aggiungi Sottocategoria' : 'Aggiungi Categoria'}</h2>
        {error && <div className="add-category-modal-error">{error}</div>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Inserisci un nome"
            required
            className="add-category-modal-input"
          />
          <div className="add-category-modal-actions">
            <button type="button" onClick={onClose} className="add-category-modal-cancel">Annulla</button>
            <button type="submit" className="add-category-modal-apply" disabled={isLoading}>
              {isLoading ? 'Caricamento...' : 'Applica'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCategoryModal;