//ModelPreview.js
import React, { forwardRef, useRef, useEffect } from 'react';
import { Canvas, useThree, useLoader } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';

const STANDARD_SIZE = 5; // Dimensione standard per tutti i piatti

function Model({ url }) {
  const gltf = useLoader(GLTFLoader, url);
  const modelRef = useRef();
  const { scene } = useThree();

  useEffect(() => {
    if (gltf && modelRef.current) {
      const model = modelRef.current;

      // Calcola la bounding box
      const box = new THREE.Box3().setFromObject(model);
      const size = box.getSize(new THREE.Vector3());
      const center = box.getCenter(new THREE.Vector3());

      // Trova la dimensione più grande
      const maxDim = Math.max(size.x, size.y, size.z);

      // Calcola la scala necessaria per raggiungere la dimensione standard
      const scale = STANDARD_SIZE / maxDim;

      // Applica la scala
      model.scale.multiplyScalar(scale);

      // Centra il modello
      model.position.sub(center.multiplyScalar(scale));

      console.log('Model dimensions:', size);
      console.log('Model scale:', scale);
    }
  }, [gltf, scene]);

  return <primitive ref={modelRef} object={gltf.scene} />;
}

export const ModelPreview = forwardRef(({ url, ambientIntensity }, ref) => {
  return (
    <div ref={ref} style={{ width: '100%', height: '100%', aspectRatio: '1 / 1' }}>
      <Canvas style={{ width: '100%', height: '100%' }}>
        <PerspectiveCamera makeDefault position={[0, STANDARD_SIZE / 2, STANDARD_SIZE]} />
        <OrbitControls />
        <ambientLight intensity={ambientIntensity} />
        <pointLight position={[10, 10, 10]} intensity={1} />
        <Model url={url} />
      </Canvas>
    </div>
  );
});

export function captureModelPreview(containerRef) {
  return new Promise((resolve) => {
    if (containerRef && containerRef.current) {
      const canvas = containerRef.current.querySelector('canvas');
      if (canvas) {
        requestAnimationFrame(() => {
          const tempCanvas = document.createElement('canvas');
          const tempCtx = tempCanvas.getContext('2d');
          tempCanvas.width = 80;
          tempCanvas.height = 80;
          tempCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, 80, 80);
          const fullDataUrl = tempCanvas.toDataURL('image/jpeg', 0.7);
          // Estraiamo solo la parte base64
          const base64 = fullDataUrl.split(',')[1];
          resolve(base64);
        });
      } else {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
}